import React from 'react'
import styled from 'react-emotion'
import Link from '../components/Link'
import { Four, One, Row, Three, Two } from '../components/Grid'
import Image from '../components/Image'
import Caption from '../components/Caption'
import SEO from '../components/SEO'
import { H2, SEOH1, SEOH4 } from '../components/Style'
import ContactForm from '../components/ContactForm'
import Layout from '../components/Layout'

const breadcrumbs = `{
  "@context": "http://schema.org",
  "@type": "BreadcrumbList",
  "itemListElement": [{
    "@type": "ListItem",
    "position": 1,
    "item": {
      "@id": "https://500tech.com/articles",
      "name": "Articles"
    }
  }]
}`

export default () => (
  <Layout>
    <SEO
      path="/articles"
      title="Articles by 500Tech"
      description="Articles for front-end developers and technical leads."
      ld={breadcrumbs}
    />

    <Row>
      <Four>
        <SEOH1>Our articles for&nbsp;engineers and&nbsp;technical&nbsp;leads</SEOH1>
      </Four>
    </Row>

    <H2>Curated materials</H2>
    <Row>
      <One>
        Hand-picked videos, articles, and books for aspiring developers.
      </One>
      <Two>
        <ul>
          <li>
            <BlogLink to="/blog/all/ryan-seddon-on-how-the-browser-renders-websites">
              Ryan Seddon on How The Browser Renders Websites
            </BlogLink>
          </li>
          <li>
            <BlogLink to="/blog/all/tim-berglund-on-advanced-git">
              Tim Berglund on Advanced Git
            </BlogLink>
          </li>
          <li>
            <BlogLink to="/blog/all/hadi-hariri-on-developing-without-a-mouse">
              Hadi Hariri on Developing Without a Mouse
            </BlogLink>
          </li>
          <li>
            <BlogLink to="/blog/all/jon-sneyers-on-optimizing-images">
              Jon Sneyers on Optimizing Images
            </BlogLink>
          </li>
          <li>
            <BlogLink to="/blog/all/robert-c-martin-on-effective-estimation">
              Robert C. Martin on Effective Estimation
            </BlogLink>
          </li>
          <li>
            <BlogLink to="/blog/all/philip-roberts-on-event-loop-in-javascript">
              Philip Roberts on Event Loop in JavaScript
            </BlogLink>
          </li>
          <li>
            <BlogLink to="/blog/all/yonatan-mevorach-on-abstract-syntax-trees">
              Yonatan Mevorach on Abstract Syntax Trees
            </BlogLink>
          </li>
          <li>
            <BlogLink to="/blog/all/jafar-husain-on-async-await">
              Jafar Husain on async/await
            </BlogLink>
          </li>
          <li>
            <BlogLink to="/blog/all/lara-hogan-on-optimizing-images-and-fonts">
              Lara Hogan on Optimizing Images and Fonts
            </BlogLink>
          </li>
          <li>
            <BlogLink to="/blog/all/addy-osmani-on-flame-charts">
              Addy Osmani on Flame Charts
            </BlogLink>
          </li>
          <li>
            <BlogLink to="/blog/all/lea-verou-on-regular-expressions">
              Lea Verou on Regular Expressions
            </BlogLink>
          </li>
          <li>
            <BlogLink to="/blog/all/where-to-start-learning-react">
              Where to Start Learning React
            </BlogLink>
          </li>
        </ul>
      </Two>
      <One>
        <Link to="https://www.youtube.com/channel/UCZ4GiUa-sCui8Kh5_rs5_EA/playlists">
          <YouTubeLogo path="yt_logo_rgb_dark.png" />
        </Link>
        <Caption>
          Explore more curated materials that we hand pick for our teams on our{' '}
          <Link to="https://www.youtube.com/channel/UCZ4GiUa-sCui8Kh5_rs5_EA/playlists">
            YouTube channel
          </Link>
        </Caption>
      </One>
    </Row>

    <H2>Redux Bad Practices</H2>
    <Row>
      <One>
        Common mistakes made when working with Redux and how to avoid them.
      </One>
      <Two>
        <ul>
          <li>
            <BlogLink to="/blog/all/redux-bad-practices-lists-as-arrays">
              Lists as Arrays
            </BlogLink>
          </li>
          <li>
            <BlogLink to="/blog/all/redux-bad-practices-duplicate-code">
              Duplicate Code
            </BlogLink>
          </li>
          <li>
            <BlogLink to="/blog/all/redux-bad-practices-nested-state">
              Nested State
            </BlogLink>
          </li>
          <li>
            <BlogLink to="/blog/all/redux-bad-practices-duplicate-state">
              Duplicate State
            </BlogLink>
          </li>
          <li>
            <BlogLink to="/blog/all/redux-bad-practices-new-objects-on-the-fly">
              New Objects On The Fly
            </BlogLink>
          </li>
          <li>
            <BlogLink to="/blog/all/redux-bad-practices-mix-ui-state-with-model-data">
              Mix UI State with Model Data
            </BlogLink>
          </li>
        </ul>
      </Two>
      <One>
        <Link to="http://redux-book.com">
          <BookImage path="home/book-redux@2x.png" />
        </Link>
        <Caption>
          Take a deep dive into Redux basics, internals, and real world
          practices with our free book:{' '}
          <Link to="http://redux-book.com">The Complete Redux Book</Link>
        </Caption>
      </One>
    </Row>

    <H2>Industry</H2>
    <Row>
      <One>
        Our personal thoughts and remarks about the front-end industry in
        general.
      </One>
      <Two>
        <ul>
          <li>
            <BlogLink to="/blog/all/why-you-should-stop-looking-at-cvs">
              Why You Should Stop Looking at CVs
            </BlogLink>
          </li>
          <li>
            <BlogLink to="/blog/all/why-do-small-frontend-teams-fail">
              Why Do Small Frontend Teams Fail?
            </BlogLink>
          </li>
          <li>
            <BlogLink to="/blog/all/best-js-framework">
              The Definitive Guide to Choosing The Best JS Framework
            </BlogLink>
          </li>
          <li>
            <BlogLink to="/blog/all/malicious-dependencies">
              Malicious Dependencies
            </BlogLink>
          </li>
          <li>
            <BlogLink to="/blog/all/why-front-end-development-is-hard">
              Why Front End Development is Hard
            </BlogLink>
          </li>
          <li>
            <BlogLink to="/blog/all/angular-vs-react-the-real-difference">
              Angular vs. React: The Real Difference
            </BlogLink>
          </li>
          <li>
            <BlogLink to="/blog/all/setting-project-expectations-before-kickoff">
              Setting Project Expectations Before Kickoff
            </BlogLink>
          </li>
          <li>
            <BlogLink to="/blog/all/benefits-of-react-native">
              Benefits of React Native
            </BlogLink>
          </li>
        </ul>
      </Two>
    </Row>

    <H2>Day-to-day</H2>
    <Row>
      <One>Articles on frameworks and languages we use in our work.</One>
      <Two>
        <ul>
          <li>
            <BlogLink to="/blog/all/controlled-components-using-hooks">
              Controlled components using hooks
            </BlogLink>
          </li>
          <li>
            <BlogLink to="/blog/all/svg-in-angular-2">
              SVG in Angular 2
            </BlogLink>
          </li>
          <li>
            <BlogLink to="/blog/all/understanding-angular-2">
              Understanding Angular 2
            </BlogLink>
          </li>
          <li>
            <BlogLink to="/blog/all/dependency-injection-in-react">
              Dependency Injection in React With Provider Pattern
            </BlogLink>
          </li>
          <li>
            <BlogLink to="/blog/all/approaches-to-immutability">
              Approaches to Immutability
            </BlogLink>
          </li>
          <li>
            <BlogLink to="/blog/all/tic-tac-toe-using-angular-mobx">
              Tic-Tac-Toe Using Angular and MobX
            </BlogLink>
          </li>
          <li>
            <BlogLink to="/blog/all/is-reactjs-fast">
              Is React Faster Than AngularJS?
            </BlogLink>
          </li>

          <li>
            <BlogLink to="/blog/all/browser-console-tricks-2">
              Browser Console Tricks #2
            </BlogLink>
          </li>

          <li>
            <BlogLink to="/blog/all/browser-console-tricks">
              Browser Console Tricks
            </BlogLink>
          </li>
          <li>
            <BlogLink to="/blog/all/techniques-for-immutable-objects">
              Techniques for Immutable Objects
            </BlogLink>
          </li>
        </ul>
      </Two>
    </Row>

    <H2>Productivity</H2>
    <Row>
      <One>Tools and tips that make our work more productive.</One>
      <Two>
        <ul>
          <li>
            <BlogLink to="/blog/all/useful-tool-regex101">Regex 101</BlogLink>
          </li>
          <li>
            <BlogLink to="/blog/all/useful-tool-octotree">Octotree</BlogLink>
          </li>
          <li>
            <BlogLink to="/blog/all/useful-tool-scm-breeze">
              SCM Breeze
            </BlogLink>
          </li>
          <li>
            <BlogLink to="/blog/all/quick-add-react-component-to-webstorm">
              Quick Add React Component to Webstorm
            </BlogLink>
          </li>
        </ul>
      </Two>
      <One>
        <Flex>
          <MimicLogo path="projects/mimic/mimic-logo.png" />
          <Caption>
            Check out <Link to="https://mimic.js.org">Mimic</Link>, our own
            useful tool for mocking server responses in a browser.
          </Caption>
        </Flex>
      </One>
    </Row>

    <H2>Technologies</H2>
    <Row>
      <One>
        Random articles about various technologies we use at work or play with
        on our free time.
      </One>
      <Three>
        <ul>
          <li>
            <BlogLink to="/blog/all/forks-in-the-blockchain-discrepancies-between-competing-chains">
              Forks in the Blockchain: Discrepancies Between Competing Chains
            </BlogLink>
          </li>
          <li>
            <BlogLink to="/blog/all/introduction-to-react-vr-part-2">
              Introduction to React VR, Part 2
            </BlogLink>
          </li>
          <li>
            <BlogLink to="/blog/all/introduction-to-react-vr-part-1">
              Introduction to React VR, Part 1
            </BlogLink>
          </li>
          <li>
            <BlogLink to="/blog/all/running-travis-ci-unit-tests-using-google-chrome">
              Running Travis CI Unit Tests Using Google Chrome
            </BlogLink>
          </li>
        </ul>
      </Three>
    </Row>

    <H2>AngularJS</H2>
    <Row>
      <One>
        Old articles on a framework we used to work with that might be relevant
        to people who are still using it.
      </One>
      <Two>
        <ul>
          <li>
            <BlogLink to="/blog/all/angularjs-dependency-injection-and-dolphins">
              AngularJS Dependency Injection And Dolphins
            </BlogLink>
          </li>
          <li>
            <BlogLink to="/blog/all/using-reactjs-with-angularjs">
              Using React with AngularJS
            </BlogLink>
          </li>
          <li>
            <BlogLink to="/blog/all/using-browserify-to-enhance-your-angularjs-tests">
              Using Browserify To Enhance Your AngularJS Tests
            </BlogLink>
          </li>
          <li>
            <BlogLink to="/blog/all/unlock-the-power-of-built-in-angular-providers">
              Unlock the Power of Built-in AngularJS Providers
            </BlogLink>
          </li>
          <li>
            <BlogLink to="/blog/all/better-view-code-in-angularjs">
              Better View Code in AngularJS
            </BlogLink>
          </li>
          <li>
            <BlogLink to="/blog/all/angularjs-quick-tip-2-catch-internal-exceptions">
              AngularJS Quick Tip: Catch Internal Exceptions
            </BlogLink>
          </li>
          <li>
            <BlogLink to="/blog/all/angularjs-quick-tip-1">
              AngularJS Quick Tip: ng-class
            </BlogLink>
          </li>
          <li>
            <BlogLink to="/blog/all/ui-router-and-case-sensitive-urls">
              UI-Router And Case-Sensitive URLs
            </BlogLink>
          </li>
        </ul>
      </Two>
    </Row>
    <ContactForm />
  </Layout>
)

const BlogLink = styled(Link)`
  font-size: 1rem;
  margin-bottom: 5px;
`

const YouTubeLogo = styled(Image)`
  margin-bottom: 20px;
  width: 60%;
`

const MimicLogo = styled(Image)`
  margin-right: 10px;
  width: 60px;
`

const BookImage = styled(Image)`
  width: 40%;
`

const Flex = styled.div`
  display: flex;
  align-items: flex-end;
`
